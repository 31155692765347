import React from "react"

import colors from "../colors"

import { LandingLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import { Button } from "semantic-ui-react"
import PersonList from "../components/person/PersonList"
import Map from "../components/map"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const LandingGEA = () => {
  return (
    <LandingLayout>
      <SEO
        title="The Long Term Care Insurance Consultation with Corey Rieck"
        ogDescription="We have arranged for you to set up a free, no-obligation LTC Consult with Corey Rieck. Corey will confidentially and personally evaluate your family’s specific needs"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Thank you for visiting from the Georgia Employers’ Association.</h1>
          
            <p>
              <Button
                as="a"
                href="https://zoom.us/webinar/register/WN_RxloD7_0RgC1FjlDRpaZ1A"
                className=""
                content="Register for the Webinar"
                size="tiny"
                icon="video"
              />
            </p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/landing/GEA_Dark300.png"
              alt="Georgia Employers’ Association"
            />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
          <p>
          Corey Rieck is the president and found of The Long Term Care Planning Group. He helps high net worth individuals better manage long-term care expenses to avoid sacrificing their financial plans and family relationships.
          </p>
          <p>
          Corey became focused on long-term care planning after managing his parents’ finances as they aged and needed long term care. He realized there had to be a better way for people to receive the best possible long term care without forfeiting assets and depleting their bank accounts.
          </p>
          <p>
          Corey knows how to help protect your financial assets and wealth management with a funding strategy for long term care. He can answer your questions and explain why you should not rely upon government programs or only your family members for long term care.
          </p>
          <p>
          We have arranged for you to set up a free, no-obligation LTC Consult with Corey Rieck. Corey will confidentially and personally evaluate your family’s specific needs and answer all your questions on long term care. This consult fee, normally valued at $99.95, is waived when you mention that you learned about us from the Georgia Employers’ Association.
          </p>
      
          <h2>Request a meeting or phone call</h2>
          <p>
          Click the button below to schedule your free, no-obligation LTC Consult with Corey Rieck of The Long Term Care Planning Group (a $99.95 value). Or, you can call <a href="tel:6788145088">(678) 814-5088</a> to schedule a meeting over the phone.
          </p>
      
          <p>
           <ScheduleVisitButton />
          </p>
          </div>
        }
      />
      <PageWrapper>
        <div
          style={{
            height: "1px",
            width: "100%",
            borderTop: "3px solid whitesmoke",
            display: "block",
            margin: "50px 0 50px 0",
          }}
        ></div>
        <PersonList shortnames={["corey-rieck"]} />
      </PageWrapper>
    </LandingLayout>
  )
}

export default LandingGEA
